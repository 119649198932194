// this navbar contains the search bar

import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import menu2Fill from "@iconify/icons-eva/menu-2-fill";

// material test3
import { alpha, experimentalStyled as styled } from "@material-ui/core/styles";
import { AppBar, Toolbar, Box, Hidden, IconButton } from "@material-ui/core";
//
import AccountPopover from "./AccountPopover";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  user: PropTypes.object,
  setUser: PropTypes.func,
  setjwtToken: PropTypes.func,
  valueMetric: PropTypes.string,
  currentFilters: PropTypes.object,
  setCurrentFilters: PropTypes.func,
  jwtToken: PropTypes.string,
  onOpenSidebar: PropTypes.func,
  searchData: PropTypes.array,
};

export default function DashboardNavbar({
  user,
  setUser,
  setjwtToken,
  valueMetric,
  currentFilters,
  setCurrentFilters,
  jwtToken,
  onOpenSidebar,
  searchData,
}) {
  return (
    <RootStyle>
      <ToolbarStyle>
        <Hidden lgUp>
          <IconButton
            onClick={onOpenSidebar}
            sx={{ mr: 1, color: "text.primary" }}
          >
            <Icon icon={menu2Fill} />
          </IconButton>
        </Hidden>

        <Box sx={{ flexGrow: 1 }} />

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            "& > *:not(:first-of-type)": {
              ml: { xs: 0.5, sm: 2, lg: 3 },
            },
          }}
        >
          <AccountPopover
            user={user}
            setjwtToken={setjwtToken}
            setUser={setUser}
          />
        </Box>
      </ToolbarStyle>
    </RootStyle>
  );
}
